import React from "react" // eslint-disable-line no-unused-vars
/** @jsx jsx */ import { jsx } from "theme-ui"
import { MDXProvider } from "@mdx-js/react"
import Divider from "./divider"
import Box from "./box"
import Paragraph from "./paragraph"
import Heading from "./heading"
import Blockquote from "./blockquote"
import Stack from "./stack"
import TextLink from "./textlink"

const CustomMDXProvider = (props) => {
  const { children } = props
  const mdxWrapper = (props) => <Stack space={12} {...props} />

  return (
    <MDXProvider
      components={{
        wrapper: mdxWrapper,
        h1: (props) => <Heading as="h2" level={2} {...props} />,
        h2: (props) => <Heading as="h3" level={3} {...props} />,
        h3: (props) => <Heading as="h4" level={4} {...props} />,
        h4: (props) => <Heading as="h5" level={5} {...props} />,
        p: (props) => <Paragraph {...props} />,
        blockquote: (props) => <Blockquote {...props} />,
        hr: (props) => <Divider size={4} {...props} />,
        ul: (props) => (
          <Paragraph as="ul" sx={{ pl: "1em", m: 0 }} {...props} />
        ),
        ol: (props) => (
          <Paragraph as="ol" sx={{ pl: "1em", m: 0 }} {...props} />
        ),
        a: (props) => <TextLink {...props} />,
        sup: ({ id, ...restProps }) => (
          <Box
            as="sup"
            id={id}
            sx={{
              fontFamily: "monospace",
              fontSize: "0.6em",
            }}
          >
            <TextLink {...restProps} />
          </Box>
        ),
      }}
    >
      <Stack
        space={12}
        sx={{
          ".anchor": { display: "none" },
        }}
      >
        {children}
      </Stack>
    </MDXProvider>
  )
}

export default CustomMDXProvider
