import React from "react"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Breadcrumb from "../components/breadcrumb"
import Layout from "./default"
import MDXProvider from "../components/mdx-provider"
import PageTitle from "../components/page-title"
import Seo from "../components/seo"
import Stack from "../components/stack"
import TableOfContents from "../components/table-of-contents"

const LayoutArchiv = ({ data: { mdx } }) => {
  const { title, backdrop } = mdx.frontmatter

  return (
    <Layout backdrop={backdrop || null}>
      <Seo title={title || null} description={mdx.excerpt} />
      <Stack>
        <Breadcrumb
          items={[
            {
              title: "Digital",
              link: "/digital",
            },
            {
              title: "Aus dem Archiv geholt",
              link: "/aus-dem-archiv-geholt",
            },
            {
              title: title,
              link: `/aus-dem-archiv-geholt${mdx.fields.slug}`,
            },
          ]}
        />
        <PageTitle>{title}</PageTitle>
        <MDXProvider>
          <MDXRenderer>{mdx.body}</MDXRenderer>
        </MDXProvider>
      </Stack>
      <TableOfContents items={mdx.tableOfContents.items} />
    </Layout>
  )
}

export const pageQuery = graphql`
  query ArchivDetailQuery($id: String) {
    mdx(id: { eq: $id }) {
      id
      body
      excerpt(pruneLength: 150)
      fields {
        slug
      }
      frontmatter {
        title
        backdrop
      }
      tableOfContents
    }
  }
`

export default LayoutArchiv
